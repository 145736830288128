.page-title {
  font-weight: bold;
}

.field {
  padding: 10px 5px;
}

.action-button {
  margin: 10px 5px;
}